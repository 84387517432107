import "./styles.css";

function NotFoundPage() {
  return (
    <div>
      <h1>Page Not Found</h1>
      <p>This page doesn't seem to exist</p>
    </div>
  );
}

export default NotFoundPage;
